import React, { useState, useMemo, useEffect } from 'react'
import {
  Modal,
  IconClose,
  useWindowDimensions,
  ListOption,
  Avatar,
  StickyButton,
  Input,
  IconSearch,
  CheckboxOption,
  IconArrowLeft,
  IconEducatorFinder
} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'
import useTranslation from '../../../../i18n/useTranslation'
import UseAllEducatorByTopicPaginated from './Model/UseAllEducatorByTopicPaginated'
import Pagination from '../../../../common/Pagination'
import { TabContent, TabPane } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import EducatorFinder from './Components/EducatorFinder'
import EducatorGrid from './Components/EducatorFinder/Pages/EducatorGrid'
import { useSelector } from 'react-redux'
import moment from 'moment'

const PAGES = {
  selector: 'selector',
  finder: 'finder',
  grid: 'grid'
}

const SelectEducator = (props) => {
  const { idTopic, setEducator, topicName, form, educator, idService, multipleSelection, useFinder = false } = props

  const [start, end] = useMemo(
    () => {
      const dates = Object.values(form?.lpw || {})?.reduce((array, {startdate, enddate}) => {
        return [...array, startdate, enddate]
      }, [])
      const minDate = dates.reduce((min, date) => {
        if(min > moment(date).utc().valueOf()) return moment(date).utc().valueOf()
        return min
      }, Number.POSITIVE_INFINITY)
      const maxDate = dates.reduce((max, date) => {
        if(max < moment(date).utc().valueOf()) return moment(date).utc().valueOf()
        return max
      }, Number.NEGATIVE_INFINITY)
      return [moment(minDate).format('YYYY-MM-DD'), moment(maxDate).format('YYYY-MM-DD')]
    }, 
    // eslint-disable-next-line
    [form?.lpw]
  )

  const timezone = useSelector((state) => state.user.timezone)
  const [isOpen, setOpen] = useState(false)
  const [page, setPage] = useState(PAGES.selector)
  const [selectEducator, setSelectEducator] = useState({})
  const [selectEducatorArray, setSelectEducatorArray] = useState([])
  const [recommended, setRecommended] = useState(educator.recommended || false)
  const { translate, replaceVariables } = useTranslation()
  const toggle = () => { setOpen(!isOpen) }
  const { breakWidth } = useWindowDimensions()
  const prospectiveParts = useMemo(() => {
    const parts = []
    if (
      (form && form.lpw && form?.lpw['0'].startdate && form?.lpw[(Object.keys(form?.lpw).length - 1).toString()].enddate) &&
      (form?.typePlan !== 'time' || form?.typeLesson !== 'total')
    ) {
      Object.keys(form.lpw).forEach((part) => {
        if (form.lpw[part][idTopic]) {
          const { startdate, enddate } = form.lpw[part]
          if (startdate && enddate) {
            parts.push({
              from: startdate,
              to: enddate,
              lessonsPerWeek: form.lpw[part][idTopic].value
            })
          }
        }
      })
    }
    return parts
  }, [form, idTopic])
  const [query, setQuery] = useState({
    topicId: '',
    serviceId: '',
    teachingTypes: [],
    prospectiveParts: [],
    educatorsId: [],

    sortField: 'firstName',
    previous: '',
    next: '',
    limit: 5,
    search: null
  })
  const { data, loading } = UseAllEducatorByTopicPaginated(query)

  useEffect(() => {
    if (idTopic && idService && prospectiveParts) {

      setQuery({
        ...query,
        topicId: idTopic,
        serviceId: idService,
        teachingTypes: ['individual'],
        prospectiveParts,
        educatorsId: [],
        previous: '',
        next: '',
        search: ''

      })
    }
    // eslint-disable-next-line
  }, [idTopic, idService, prospectiveParts])

  const { educators, paginatedData } = useMemo(
    () => {
      if (data?.allEducatorByTopicPaginated) {
        const { docs, ...paginatedData } = data.allEducatorByTopicPaginated
        return {
          educators: docs, paginatedData
        }
      }
      return {
        educators: [], paginatedData: {}
      }
    }, [data]
  )

  const [educatorSearch, setEducatorSearch] = useState(null)

  const pushEducatorToArray = (edu) => {
    const { id } = edu
    if (selectEducatorArray.map(({ id }) => id).includes(id)) {
      //elimina educador del arreglo selectEducatorArray
      setSelectEducatorArray(selectEducatorArray.reduce((newArray, eduObject) => {
        if (eduObject.id !== id) newArray.push(eduObject)
        return newArray
      }, []))
    }
    else setSelectEducatorArray(selectEducatorArray.concat([edu]))
  }
  const [currentPage, setCurrentPage] = useState(1)

  const [finderEducators, setFinderEducators] = useState([])
  const [finderForm, setFinderForm] = useState({})
  const [educatorGrid, setEducatorGrid] = useState(null)
  useEffect(() => {
    if(!isOpen) {
      //reset finder results
      setEducatorGrid(null)
      setFinderEducators([])
      setFinderForm({})
      setPage(PAGES.selector)
    }
  }, [isOpen])


  const partsShortCuts = useMemo(
    () => {
      if(!form?.lpw) return []
      const parts = Object.values(form.lpw)
      return parts.map(
        (part) => {
          const {partname, startdate, enddate} = part
          return {label: partname, start: startdate, end: enddate}
        }
      )
    }, 
    // eslint-disable-next-line
    [form?.lpw]
  )


  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0 my-3'>
            <div className='d-flex flex-row align-items-center justify-content-start m-0 p-0' style={{ gap: 10 }}>
              {
                [PAGES.grid, PAGES.finder].includes(page) && (
                  <div className='hover-icon' onClick={(e) => {
                    setPage(PAGES.selector)
                  }}><IconArrowLeft size={20} /></div>
                )
              }
              <span className='flex-grow-1 text-title font-weight-bold h2'>
                {translate('modal.newplan.selecteducator.title')}
              </span>
            </div>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <div className='p-0 m-4'>
          <TabContent activeTab={page}>
            <TabPane tabId={PAGES.selector}>
              <>
                <div className='w-100 d-flex flex-row justify-content-between align-items-center mb-3'>
                  <Input
                    className={`${useFinder ? 'w-85' : 'w-100'} input-xs p-0`}
                    placeholder={`${translate('modal.newplan.selecteducator.search')} ${topicName}`}
                    addOnComponent={<IconSearch size={16} />}
                    type='addon'
                    onChange={(e) => {
                      setEducatorSearch(e.target.value)
                    }}
                    onKeyUp={({ key }) => {
                      if (key === 'Enter') {
                        setCurrentPage(1)
                        setQuery({ ...query, search: educatorSearch, next: '', previous: '' })
                      }
                    }}
                  />
                  {
                    useFinder && (
                      <div className='w-auto d-flex flex-row justify-content-center'>
                        <div className='hover-icon' onClick={() => { setPage(PAGES.finder) }}>
                          <IconEducatorFinder className={'text-gray'} size={25} />
                        </div>
                      </div>
                    )
                  }
                </div>
                <div className='m-0 py-2 px-0'>
                  <TabContent activeTab={loading ? 'loading' : 'show'}>
                    <TabPane tabId={`loading`}>
                      <Skeleton style={{ borderRadius: '10px' }} count={query?.limit || 5} className={`w-100 mb-1`} height={40} />
                    </TabPane>
                    <TabPane tabId={`show`}>
                      {
                        educators.map((edu) => {
                          const frameEducator = finderEducators.find(({ id }) => id === edu.id)
                          const smallText = replaceVariables(
                            translate("modal.newplan.selecteducator.finder.availabilityLabel"),
                            {TOTAL: finderForm?.frames?.length, SIZE: frameEducator?.freeFramesBlocs?.length}  
                          )
                          // `${frameEducator?.freeFramesBlocs?.length} of ${finderForm?.frames?.length} blocks free`
                          return (
                            <ListOption
                              key={edu.id}
                              className='mb-2'
                              id='x-0'
                              label={null}
                              selectedInput={
                                multipleSelection ?
                                  selectEducatorArray.map(({ id }) => id).includes(edu.id) :
                                  selectEducator.id === edu.id
                              }
                              childClassName='w-100'
                              icon={
                                <div className='d-flex align-items-center text-muted'>
                                  <Avatar
                                    name={`${edu.firstName} ${edu.lastName}`}
                                    named={false}
                                    uri={null}
                                    size='avatar-small avatar-span-white'
                                  />
                                  <div className='d-flex align-items-center justify-content-between w-100'>
                                    <span className='ml-2 h4'>
                                      {`${edu.firstName} ${edu.lastName}`}
                                    </span>
                                    {/* {((parseInt(edu.educatorInfo.isCapped.split('/')[0]) > 0)) &&
                                      <span className='mr-2'>
                                        {`${edu.educatorInfo.isCapped.split('/')[0]} ${translate('modal.newplan.selecteducator.outof')} ${edu.educatorInfo.isCapped.split('/')[1]} ${translate('modal.newplan.selecteducator.weeks')}`}
                                      </span>} */}

                                    {frameEducator &&
                                      <span title={smallText} className='btn-link text-gray h45 mr-2 text-truncate' onClick={(e) => {
                                        e.stopPropagation()
                                        e.nativeEvent.stopImmediatePropagation()
                                        setEducatorGrid(frameEducator)
                                        setPage(PAGES.grid)
                                      }}>
                                        {smallText}
                                      </span>}
                                  </div>
                                </div>
                              }
                              onClick={() => {
                                if (multipleSelection) pushEducatorToArray(edu)
                                else setSelectEducator(edu)
                              }}
                            />
                          )
                        })
                      }
                    </TabPane>
                  </TabContent>
                  <Pagination
                    size={`small`}
                    useSelectorChangeSize={false}
                    pageSize={query?.limit || 5}
                    changeSize={() => { }}
                    clickNext={() => {
                      if (paginatedData?.hasNext) {
                        setCurrentPage(currentPage + 1)
                        setQuery({ ...query, next: paginatedData?.next, previous: '' })
                      }
                    }}
                    clickPrevious={() => {
                      if (paginatedData?.hasPrevious) {
                        setCurrentPage(currentPage - 1)
                        setQuery({ ...query, previous: paginatedData?.previous, next: '' })
                      }
                    }}
                    hasPrevious={paginatedData?.hasPrevious}
                    hasNext={paginatedData?.hasNext}
                    activePage={currentPage}
                    pageCount={Math.ceil((paginatedData?.totalDocs || 0) / (query?.limit || 1))}
                    loading={loading}
                  />
                </div>
                <div className='d-flex px-2 mb-4'>
                  <CheckboxOption
                    name='recommended' defaultState={recommended}
                    onChange={(e) => {
                      setRecommended(e)
                    }}
                  />
                  <span title={translate('modal.newplan.selecteducator.allow')}
                  >{translate('modal.newplan.selecteducator.allow')}
                  </span>
                </div>
                <StickyButton
                  color='#696CFF'
                  condition={true}
                  disabled={
                    multipleSelection ?
                      selectEducatorArray.length === 0 :
                      JSON.stringify(selectEducator) === JSON.stringify({})
                  }
                  label={translate('modal.newplan.selecteducator.title')}
                  onClickButtonFunction={() => {
                    setEducator(
                      multipleSelection ?
                        selectEducatorArray :
                        { ...selectEducator, recommended: recommended }
                    )
                    toggle()
                  }}
                />
              </>
            </TabPane>
            <TabPane tabId={PAGES.finder}>
              <>
                <EducatorFinder
                  onSubmit={(educators = []) => {
                    setFinderEducators(educators)
                    setQuery({ ...query, educatorsId: educators.map(({ id }) => id) })
                    setPage(PAGES.selector)
                  }}
                  defaultStart={start ? moment(start).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                  defaultEnd={end ? moment(end).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                  defaultStudentId={form?.student?.studentUserId || form?.student?.id}
                  defaultServiceId={idService}
                  defaultTopicId={idTopic}
                  form={finderForm}
                  setForm={setFinderForm}
                  className={'pl-2'}
                  dateShortCuts={partsShortCuts}
                />
              </>
            </TabPane>
            <TabPane tabId={PAGES.grid}>
              <>
                <EducatorGrid
                  className={''}
                  zone={timezone}
                  educator={educatorGrid}
                  start={finderForm?.start || new Date()}
                  end={finderForm?.end || new Date()}
                  frames={finderForm?.frames || []}
                />
              </>
            </TabPane>
          </TabContent>
        </div>
      </Modal>
    </>
  )
}

SelectEducator.propTypes = {
  idTopic: PropTypes.string,
  setEducator: PropTypes.func,
  educator: PropTypes.object,
  isNewLesson: PropTypes.bool,
  multipleSelection: PropTypes.bool
}

SelectEducator.defaultProps = {
  idTopic: '5f58e6b11b8c4862420e394b',
  setEducator: () => { },
  educator: { id: '', educator: '' },
  isNewLesson: false,
  multipleSelection: false
}
export default SelectEducator
